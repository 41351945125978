import React from "react";
import ProductTemplate from "./product";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <>
                <p>
                    El llibre de les 500 preguntes imprescindibles per accedir a la Guàrdia Urbana
                    de Barcelona. Un recopilatori preguntes oficials que han aparegut en els exàmens
                    de les convocatòries d'anys anteriors i altres preguntes que considerem
                    bàsiques.
                </p>

                <p>
                    manera diferent d'aprendre. Totes les preguntes contenen la resposta correcta i
                    en moltes d'elles hem afegit ampliació d'informació.
                </p>

                <p>
                    Trobaràs tots els temes de les bases del temari, inclosos els dos temes nous
                    d'aquest any: l'ordenança relativa a la restricció de la circulació de
                    determinats vehicles a la ciutat de Barcelona i la de medi ambient!
                </p>

                <p>
                    Amb la compra del llibre t'habilitem de manera{" "}
                    <strong>TOTALMENT GRATUÏTA</strong> el mòdul de les 500 preguntes en format test
                    per practicar el que has estudiat i posar-te a prova! Un mòdul que podràs fer
                    servir a través de l'aplicació iGuB a qualsevol moment i allà on vulguis.
                </p>

                <p>
                    Vols ser agent de la Guàrdia Urbana de Barcelona? Fes-te amb aquest llibre únic
                    i no et quedis enrere!
                </p>
            </>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="Domina les oposicions a la Guàrdia Urbana de Barcelona amb el llibre de les 500 preguntes imprescindibles! Inclou respostes correctes, ampliacions d'informació i temes actualitzats."
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;

/*
import React from "react";
import ProductTemplate from "./product";
import Title from "@components/common/Title";
import Text from "@components/common/Text";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    return (
        <ProductTemplate item={item}>
            
            <div className="container lg:px-0 py-20">
                <Title>El Libro</Title>
                <div className="mt-10">
                    <Text className="text-justify">
                        El llibre de les 500 preguntes imprescindibles per accedir a la Guàrdia
                        Urbana de Barcelona. Un recopilatori preguntes oficials que han aparegut en
                        els exàmens de les convocatòries d'anys anteriors i altres preguntes que
                        considerem bàsiques.
                        <br />
                        <br />
                        Un complement perfecte per profunditzar en el teu procés d'estudi; una
                        manera diferent d'aprendre. Totes les preguntes contenen la resposta
                        correcta i en moltes d'elles hem afegit ampliació d'informació.
                        <br />
                        <br />
                        Trobaràs tots els temes de les bases del temari, inclosos els dos temes nous
                        d'aquest any: l'ordenança relativa a la restricció de la circulació de
                        determinats vehicles a la ciutat de Barcelona i la de medi ambient!
                        <br />
                        <br />
                        Amb la compra del llibre t'habilitem de manera TOTALMENT GRATUÏTA el mòdul
                        de les 500 preguntes en format test per practicar el que has estudiat i
                        posar-te a prova! Un mòdul que podràs fer servir a través de l'aplicació
                        iGuB a qualsevol moment i allà on vulguis.
                        <br />
                        <br />
                        Vols ser agent de la Guàrdia Urbana de Barcelona? Fes-te amb aquest llibre
                        únic i no et quedis enrere!
                    </Text>
                </div>
            </div>
        </ProductTemplate>
    );
};
export default Product;
*/
